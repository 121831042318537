<template>
  <div>
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <b-row>
      <b-col
        cols="12"
        xl="9"
        lg="8"
        md="7"
      >
        <b-card>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="'https://servo.blob.core.windows.net/photos/' + registrant.photo"
                  :text="avatarText(registrant.name)"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ registrant.name }}
                    </h4>
                    <span class="card-text">{{ registrant.email }}</span>
                  </div>
                </div>
              </div>

              <!-- User Stats -->
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar
                    variant="light-primary"
                    rounded
                  >
                    <feather-icon
                      icon="LinkIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ registrant.connectGroupCategory }}
                    </h5>
                    <small>Penggembalaan</small>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <b-avatar
                    :variant="cgPresenceResult(cgPresencePercentage)"
                    rounded
                  >
                    <feather-icon
                      icon="BarChartIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ cgPresencePercentage }}%
                    </h5>
                    <small>Percentage of CG Presence</small>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th
                    style="width: 40%;"
                    class="pb-50"
                  >
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Age</span>
                  </th>
                  <td class="pb-50">
                    {{ moment().format('YYYY') - moment(registrant.birthDate).format('YYYY') }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UsersIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Gender</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ registrant.gender }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="ChevronsUpIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">CG Level</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ registrant.connectGroupLevel }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="SmileIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">CG Name</span>
                  </th>
                  <td class="pb-50">
                    {{ registrant.connectGroupName }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Phone</span>
                  </th>
                  <td class="pb-50">
                    {{ registrant.phone }}
                  </td>
                </tr>
                <tr v-if="registrant.serviceCategory">
                  <th class="pb-50">
                    <feather-icon
                      icon="HomeIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Service Category</span>
                  </th>
                  <td class="pb-50">
                    {{ registrant.serviceCategory }}
                  </td>
                </tr>
                <tr v-if="registrant.firstOptionMinistry">
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">First Option Schedule</span>
                  </th>
                  <td
                    v-if="(registrant.firstOptionMinistrySchedule && registrant.firstOptionMinistrySchedule.auditionRoom && registrant.firstOptionMinistrySchedule.auditionSchedule) && (registrant.firstOptionMinistrySchedule && registrant.firstOptionMinistrySchedule.interviewRoom && registrant.firstOptionMinistrySchedule.interviewSchedule)"
                    class="pb-50"
                  >
                    <li>Audisi: {{ moment(registrant.firstOptionMinistrySchedule.auditionSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.auditionRoom }})</li>
                    <li>Interview: {{ moment(registrant.firstOptionMinistrySchedule.interviewSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.interviewRoom }})</li>
                  </td>
                  <td
                    v-else-if="(registrant.firstOptionMinistrySchedule && registrant.firstOptionMinistrySchedule.auditionRoom && registrant.firstOptionMinistrySchedule.auditionSchedule) && ((registrant.firstOptionMinistrySchedule && registrant.firstOptionMinistrySchedule.interviewRoom && !registrant.firstOptionMinistrySchedule.interviewSchedule) || (registrant.firstOptionMinistrySchedule && !registrant.firstOptionMinistrySchedule.interviewRoom && registrant.firstOptionMinistrySchedule.interviewSchedule) || (registrant.firstOptionMinistrySchedule && !registrant.firstOptionMinistrySchedule.interviewRoom && !registrant.firstOptionMinistrySchedule.interviewSchedule))"
                  >
                    <li>Audisi: {{ moment(registrant.firstOptionMinistrySchedule.auditionSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.auditionRoom }})</li>
                    <li>Interview: Dihubungi PIC</li>
                  </td>
                  <td
                    v-else-if="((registrant.firstOptionMinistrySchedule && registrant.firstOptionMinistrySchedule.auditionRoom && !registrant.firstOptionMinistrySchedule.auditionSchedule) || (registrant.firstOptionMinistrySchedule && !registrant.firstOptionMinistrySchedule.auditionRoom && registrant.firstOptionMinistrySchedule.auditionSchedule) || (registrant.firstOptionMinistrySchedule && !registrant.firstOptionMinistrySchedule.auditionRoom && !registrant.firstOptionMinistrySchedule.auditionSchedule)) && (registrant.firstOptionMinistrySchedule && registrant.firstOptionMinistrySchedule.interviewRoom && registrant.firstOptionMinistrySchedule.interviewSchedule && firstDivisionType === 2)"
                  >
                    {{ moment(registrant.firstOptionMinistrySchedule.interviewSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.interviewRoom }})
                  </td>
                  <td
                    v-else-if="((registrant.firstOptionMinistrySchedule && registrant.firstOptionMinistrySchedule.auditionRoom && !registrant.firstOptionMinistrySchedule.auditionSchedule) || (registrant.firstOptionMinistrySchedule && !registrant.firstOptionMinistrySchedule.auditionRoom && registrant.firstOptionMinistrySchedule.auditionSchedule) || (registrant.firstOptionMinistrySchedule && !registrant.firstOptionMinistrySchedule.auditionRoom && !registrant.firstOptionMinistrySchedule.auditionSchedule)) && (registrant.firstOptionMinistrySchedule && registrant.firstOptionMinistrySchedule.interviewRoom && registrant.firstOptionMinistrySchedule.interviewSchedule)"
                  >
                    <li>Audisi: Dihubungi PIC</li>
                    <li>Interview: {{ moment(registrant.firstOptionMinistrySchedule.interviewSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.interviewRoom }})</li>
                  </td>
                  <td v-else>
                    <li>Audisi: Dihubungi PIC</li>
                    <li>Interview: Dihubungi PIC</li>
                  </td>
                </tr>
                <tr
                  v-if="registrant.secondOptionMinistry"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Second Option Schedule</span>
                  </th>
                  <td
                    v-if="registrant.secondOptionMinistry"
                    class="pb-50"
                  >
                    {{ moment(registrant.secondOptionMinistrySchedule.interviewSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.secondOptionMinistrySchedule.interviewRoom }})
                  </td>
                  <td
                    v-else-if="!registrant.secondOptionMinistrySchedule.interviewRoom || !registrant.secondOptionMinistrySchedule.interviewSchedule"
                  >
                    Dihubungi PIC
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="5"
        xl="3"
        lg="4"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-27">
            <h5 class="mb-0">
              Current Status
            </h5>
            <b-badge
              :variant="`light-${registrant.servolutionRegistrationStatus.style}`"
              style="font-size: 10px;"
            >
              {{ registrant.servolutionRegistrationStatus.name }}
            </b-badge>
            <small class="text-muted w-100">{{ moment().format("dddd, DD MMMM YYYY") }}</small>
          </b-card-header>
          <b-card-body>
            <ul
              v-if="registrant.firstMinistry && registrant.leaderPeople"
              class="list-unstyled my-1"
            >
              <li v-if="registrant.firstMinistry">
                <span class="align-middle">First Option: {{ registrant.firstMinistry.name }}</span>
              </li>
              <li
                v-if="registrant.secondMinistry"
                class="my-25"
              >
                <span class="align-middle">Second Option: {{ registrant.secondMinistry ? registrant.secondMinistry.name: '-' }}</span>
              </li>
              <li>
                <span
                  v-if="registrant.leaderPeople"
                  class="align-middle"
                >
                  Approved By: {{ registrant.leaderPeople.name }} ({{ registrant.leaderPeople.cellPhone }})
                </span>
              </li>
            </ul>
            <b-badge
              variant="light-primary"
              class="d-block"
            >
              Registered on {{ moment(registrant.createdAt).format("DD MMMM YYYY") }}
            </b-badge>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="registrant.servolutionRegistrationStatusId !== 69">
      <b-col
        v-if="registrant.servolutionRegistrationStatusId !== 13"
        cols="12"
        lg="12"
      >
        <b-card title="CG Recommendation">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`1. Apakah ${registrant.name} dapat menjalin hubungan / kerjasama yang baik dengan setiap orang dalam anggota CG atau dengan pemimpin ?`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.menjalinHubunganKerjaSamaDiCg"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`2. Komentar / catatan dari pemimpin`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.leaderNote"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="12"
      >
        <!-- Initial Application Form -->
        <b-card title="Initial Application Form">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`1. Arti pelayanan bagi ${registrant.name} secara pribadi`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.artiPelayanan"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`2. Alasan kenapa ${registrant.name} ingin melayani di bidang ini`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.alasanPelayanan"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`3. ${registrant.name} pernah mempunyai pengalaman di bidang ini sebelumnya ?`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.pengalamanBidang"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`4. Lama ${registrant.name} menekuni bidang ini`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.berapaLamaMenekuniBidangIni"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`5. Ada prestasi yang pernah dicapai dalam bidang yang diikuti ?`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.prestasiDalamBidang"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`6. Bersedia meluangkan waktu minimal 1x dalam 1 minggu di malam hari untuk menghadiri pertemuan - pertemuan terkait dengan pelayanan yang dipilih ? (online / onsite)`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.bersediaMeluangkanWaktu"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`7. Keluarga mendukung dalam pelayanan ?`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.keluargaMendukung"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`8. Tempat belajar ${registrant.name} di dalam menambah pengetahuan di bidang ini`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.dimanaBelajarBidang"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`9. Hobi`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.hobby"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`10. Aktivitas / keanggotaan ${registrant.name} sebagai bentuk nyata dari hobi`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.aktivitasNyataHobby"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`11. Kegiatan yang menyita waktu dalam keseharian`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.kegiatanMenyitaWaktu"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`12. Apakah kegiatan di atas mempengaruhi kegiatan pelayanan ?`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.kegiatanMempengaruhiPelayanan"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`13. Apakah masih terlibat dalam pelayanan di komunitas lain / gereja lain ?`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.pelayananDiGerejaLain ? registrant.pelayananDiGerejaLain : '-'"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`14. Yang ingin dicapai ${registrant.name} dalam hidup secara pribadi dalam 5 tahun ke depan`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.apaYangInginDicapaiDalam_5Tahun"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`15. Susunan prioritas hidup ${registrant.name}`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.susunanPrioritas"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`16. 3 kekuatan dan kelemahan ${registrant.name}`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.kekuatanKelemahan"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`17. Talenta`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.talenta"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`18. Talenta yang dimiliki apakah bisa menjadi motivasi ${registrant.name} untuk menjadi volunteer di bidang pelayanan yang dipilih sekarang`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.talentaMenjadiMotivasi"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`19. Skill khusus atau keahlian yang membuat ${registrant.name} berbeda dengan lainnya`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.skillKhusus"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`20. Pendidikan terakhir ${registrant.name} dan dimana menempuh pendidikan tersebut ?`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.pendidikanTerakhir"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`21. Value hidup dari ${registrant.name}`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.valueHidup"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`22. Pergumulan yang sedang dihadapi ${registrant.name} saat ini`"
                  class="font-weight-bold"
                >
                  <span class="font-italic">Hubungan</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pergumulanHubungan }}</span><br>
                  <span class="font-italic">Pekerjaan / Studi</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pergumulanPekerjaanStudi }}</span><br>
                  <span class="font-italic">Kesehatan</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pergumulanKesehatan }}</span><br>
                  <span class="font-italic">Keuangan</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pergumulanKeuangan }}</span><br>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`23. Apakah ${registrant.name} RUTIN melakukan SAAT TEDUH pribadi ?`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.rutinSaatTeduh"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`24. 3 nama pemimpin rohani / orang tua rohani / teman rohnai yang mengenal dan memperhatikan pertumbuhan rohani ${registrant.name}`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.namaRohaniawan"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`25. Ayat Favorit`"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    plaintext
                    class="font-italic"
                    :value="registrant.ayatFavorit ? registrant.ayatFavorit : '-'"
                    rows="1"
                    max-rows="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`26. Pendapat tentang hal - hal di bawah ini.`"
                  class="font-weight-bold"
                >
                  <span class="font-italic">Mengkomsumsi Minuman Beralkohol</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatMinumanBeralkohol }}</span><br>
                  <span class="font-italic">Merokok</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatMerokok }}</span><br>
                  <span class="font-italic">Perceraian dan pernikahan kembali</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatPerceraian }}</span><br>
                  <span class="font-italic">Aborsi karena terpaksa</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatAborsi }}</span><br>
                  <span class="font-italic">Homoseksual</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatHomoseksual }}</span><br>
                  <span class="font-italic">Hutang - hutang pribadi</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatHutangPribadi }}</span><br>
                  <span class="font-italic">Tatoo</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatTatoo }}</span><br>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`27. Status`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.status"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`28. Nama Pasangan`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.namaPasangan ? registrant.namaPasangan: '-'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="registrant.servolutionRegistrationStatusId === 69">
      <b-col
        cols="12"
        lg="12"
      >
        <b-card
          text-variant="center"
          bg-variant="danger"
        >
          <b-avatar
            variant="danger"
            size="70"
            class="shadow mb-2"
          >
            <feather-icon
              size="28"
              icon="AlertCircleIcon"
            />
          </b-avatar>
          <h1 class="mb-1 mt-50 text-white">
            This Participant has not been baptized by the Holy Spirit.
          </h1>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="12"
      >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          block
          variant="outline-primary"
          :to="{ name: 'servolution-participants', params: { id: registrant.servolutionEventsId } }"
        >
          Back to previous page
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="registrant.servolutionRegistrationStatusId === 3 || registrant.servolutionRegistrationStatusId === 13">
      <b-col
        cols="12"
        lg="12"
      >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          block
          variant="outline-primary"
          :to="{ name: 'servolution-participants', params: { id: registrant.servolutionEventsId } }"
        >
          Back to previous page
        </b-button>
      </b-col>
    </b-row>

    <!-- If the judge is for the first option -->
    <span v-if="registrant.firstMinistry && authorizedDivisions.indexOf(registrant.firstMinistry.id) > -1">
      <!-- If the division type has audition and the registrant has been approved -->
      <b-row v-if="firstDivisionType === 1 && registrant.servolutionRegistrationStatusId === 2">
        <b-col
          cols="12"
          lg="12"
        >
          <b-card title="Audition">
            <validation-observer ref="auditionForm">
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Audition Result"
                      rules="required"
                    >
                      <b-form-group
                        label="Lulus Audisi ?"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="auditionResult"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <!-- * For PAW Regional Jabodetabek -->
                          <b-form-radio
                            v-if="registrant.firstMinistry.ministryId === '6cb49e2200e22bbef1fc311f240927c3' && registrant.firstMinistry.id !== '6cb49e2200e22bbef1fc311f24086425' && !registrant.firstMinistry.name.includes('Kidz') && churchRegionalId === '036244d52cf0bdd764eff0d822c40257'"
                            v-model="auditionResult"
                            value="paw_kidz"
                            class="custom-control-primary"
                          >
                            Ya (Diarahkan ke PAW Eaglekidz)
                          </b-form-radio>
                          <b-form-radio
                            v-model="auditionResult"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      class="mr-1"
                      type="submit"
                      @click.prevent="validationAuditionForm"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      :to="{ name: 'servolution-participants', params: { id: eventId } }"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>

      <!-- If the user passed the audition or the first option is for interview only, then show the interview form -->
      <b-row v-else-if="(firstDivisionType === 1 && (registrant.servolutionRegistrationStatusId === 4 || registrant.servolutionRegistrationStatusId === 67 || registrant.servolutionRegistrationStatusId === 100)) || (firstDivisionType === 2 && registrant.servolutionRegistrationStatusId === 2)">
        <!-- If pass the audition, then add the congratz card for the first option. -->
        <b-col
          v-if="firstDivisionType === 1 && registrant.servolutionRegistrationStatusId === 4"
          cols="12"
          lg="12"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <!-- images -->
            <b-img
              src="@/assets/images/elements/decore-left.png"
              class="congratulations-img-left"
            />
            <b-img
              src="@/assets/images/elements/decore-right.png"
              class="congratulations-img-right"
            />
            <!--/ images -->

            <b-avatar
              variant="success"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="CheckIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Pass the Audition
            </h1>
          </b-card>
        </b-col>

        <!-- If Audition passed for kidz (PAW) -->
        <b-col
          v-if="firstDivisionType === 1 && registrant.servolutionRegistrationStatusId === 67"
          cols="12"
          lg="12"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <!-- images -->
            <b-img
              src="@/assets/images/elements/decore-left.png"
              class="congratulations-img-left"
            />
            <b-img
              src="@/assets/images/elements/decore-right.png"
              class="congratulations-img-right"
            />
            <!--/ images -->

            <b-avatar
              variant="success"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="CheckIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Pass the Audition for PAW Kidz
            </h1>
          </b-card>
        </b-col>

        <!-- Interview Form -->
        <b-col
          cols="12"
          lg="12"
        >
          <b-card title="Interview Form">
            <validation-observer ref="interviewSummaryForm">
              <b-form>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Dasar Kerohanian"
                      rules="required"
                    >
                      <b-form-group
                        label="1. Dasar Kerohanian"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="dasarKerohanian"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="dasarKerohanian"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <!-- <quill-editor
                          v-model="dasarKerohanianNotes"
                          :options="snowOption"
                          style="min-height: 100px;"
                        /> -->
                        <b-form-textarea
                          v-model="dasarKerohanianNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Apakah ada masalah hidup yang mendalam"
                      rules="required"
                    >
                      <b-form-group
                        label="2. Apakah ada masalah hidup yang mendalam"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="tidakAdaMasalahHidup"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="tidakAdaMasalahHidup"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="tidakAdaMasalahHidupNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Komitmen dalam Pelayanan"
                      rules="required"
                    >
                      <b-form-group
                        label="3. Komitmen dalam Pelayanan"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="komitmenDalamPelayanan"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="komitmenDalamPelayanan"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="komitmenDalamPelayananNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggung Jawab dalam Kehidupan"
                      rules="required"
                    >
                      <b-form-group
                        label="4. Tanggung Jawab dalam Kehidupan"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="tanggungJawabDalamKehidupan"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="tanggungJawabDalamKehidupan"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="tanggungJawabDalamKehidupanNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Kedewasaan dalam Cara Berpikir"
                      rules="required"
                    >
                      <b-form-group
                        label="5. Kedewasaan dalam Cara Berpikir"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="kedewasaanBerpikir"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="kedewasaanBerpikir"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="kedewasaanBerpikirNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="role === 'PIC Ministry'"
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Interview Result"
                      rules="required"
                    >
                      <b-form-group
                        label="Lulus Interview ?"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="interviewResult"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="interviewResult"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      class="mr-1"
                      type="submit"
                      @click.prevent="validationInterviewForm"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      :to="{ name: 'servolution-participants', params: { id: eventId } }"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>

      <!-- If the user has been assessed and need approval from kadept or if the first option is audition type and the user fail or if the user succeed or fail the interview. -->
      <b-row v-else-if="(registrant.servolutionRegistrationStatusId === 34 && role === 'PIC Ministry') || (registrant.servolutionRegistrationStatusId === 5 || registrant.servolutionRegistrationStatusId === 6 || registrant.servolutionRegistrationStatusId === 7 || registrant.servolutionRegistrationStatusId === 8)">
        <!-- If audition pass -->
        <b-col
          v-if="firstDivisionType === 1 && registrant.isPassedFirstOptionMinistry && registrant.servolutionRegistrationStatusId === 4"
          cols="12"
          lg="12"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <!-- images -->
            <b-img
              src="@/assets/images/elements/decore-left.png"
              class="congratulations-img-left"
            />
            <b-img
              src="@/assets/images/elements/decore-right.png"
              class="congratulations-img-right"
            />
            <!--/ images -->

            <b-avatar
              variant="success"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="CheckIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Pass the Audition
            </h1>
          </b-card>
        </b-col>

        <!-- If audition fail -->
        <b-col
          v-if="(firstDivisionType === 1 && (!registrant.isPassedFirstOptionMinistry || (registrant.servolutionRegistrationStatusId === 5 || registrant.servolutionRegistrationStatusId === 6)))"
          cols="12"
          lg="12"
        >
          <b-card
            text-variant="center"
            bg-variant="secondary"
          >
            <b-avatar
              variant="danger"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="XIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Audition Failed (Second)
            </h1>
          </b-card>
          <b-card
            v-if="authorizedDivisions.indexOf(registrant.secondMinistry.id) > -1"
            title="Interview Form"
          >
            <validation-observer ref="interviewSummaryForm">
              <b-form>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Dasar Kerohanian"
                      rules="required"
                    >
                      <b-form-group
                        label="1. Dasar Kerohanian"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="dasarKerohanian"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="dasarKerohanian"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="dasarKerohanianNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Apakah ada masalah hidup yang mendalam"
                      rules="required"
                    >
                      <b-form-group
                        label="2. Apakah ada masalah hidup yang mendalam"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="tidakAdaMasalahHidup"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="tidakAdaMasalahHidup"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="tidakAdaMasalahHidupNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Komitmen dalam Pelayanan"
                      rules="required"
                    >
                      <b-form-group
                        label="3. Komitmen dalam Pelayanan"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="komitmenDalamPelayanan"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="komitmenDalamPelayanan"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="komitmenDalamPelayananNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggung Jawab dalam Kehidupan"
                      rules="required"
                    >
                      <b-form-group
                        label="4. Tanggung Jawab dalam Kehidupan"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="tanggungJawabDalamKehidupan"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="tanggungJawabDalamKehidupan"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="tanggungJawabDalamKehidupanNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Kedewasaan dalam Cara Berpikir"
                      rules="required"
                    >
                      <b-form-group
                        label="5. Kedewasaan dalam Cara Berpikir"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="kedewasaanBerpikir"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="kedewasaanBerpikir"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="kedewasaanBerpikirNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="role === 'PIC Ministry'"
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Interview Result"
                      rules="required"
                    >
                      <b-form-group
                        label="Lulus Interview ?"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="interviewResult"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="interviewResult"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      class="mr-1"
                      type="submit"
                      @click.prevent="validationInterviewForm"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      :to="{ name: 'servolution-participants', params: { id: eventId } }"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>

        <b-col
          v-if="(registrant.servolutionRegistrationStatusId === 34 && role === 'PIC Ministry') || (registrant.servolutionRegistrationStatusId === 7 || registrant.servolutionRegistrationStatusId === 8)"
          cols="12"
          lg="12"
        >
          <!-- Show Interview Summary report -->
          <b-card title="Interview Summary Report">
            <validation-observer ref="interviewSummaryForm">
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`1. Dasar Kerohanian`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.dasarKerohanian"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Dasar Kerohanian`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.dasarKerohanianNotes === '' ? '-' : interviewSummaryReport.dasarKerohanianNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`2. Apakah ada masalah hidup yang mendalam`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.tidakAdaMasalahHidup"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Apakah ada masalah hidup yang mendalam`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.tidakAdaMasalahHidupNotes === '' ? '-' : interviewSummaryReport.tidakAdaMasalahHidupNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`3. Komitmen dalam Pelayanan`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.komitmenDalamPelayanan"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Komitmen dalam Pelayanan`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.komitmenDalamPelayananNotes === '' ? '-' : interviewSummaryReport.komitmenDalamPelayananNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`4. Tanggung Jawab dalam Kehidupan`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.tanggungJawabDalamKehidupan"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Tanggung Jawab dalam Kehidupan`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.tanggungJawabDalamKehidupanNotes === '' ? '-' : interviewSummaryReport.tanggungJawabDalamKehidupanNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`5. Kedewasaan dalam Cara Berpikir`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.kedewasaanBerpikir"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Kedewasaan dalam Cara Berpikir`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.kedewasaanBerpikirNotes === '' ? '-' : interviewSummaryReport.kedewasaanBerpikirNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Nama Interviewer`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.namaInterviewer"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Acc. Nama Kadept`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.accNamaKadept"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="registrant.servolutionRegistrationStatusId === 34 && role === 'PIC Ministry'">
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Interview Result"
                      rules="required"
                    >
                      <b-form-group
                        label="Lulus Interview ?"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="interviewResult"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="interviewResult"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="registrant.servolutionRegistrationStatusId === 34 && role === 'PIC Ministry'">
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      class="mr-1"
                      type="submit"
                      @click.prevent="validationInterviewForm"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      :to="{ name: 'servolution-participants', params: { id: eventId } }"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>

          <!-- If interview succeed -->
          <b-card
            v-if="registrant.servolutionRegistrationStatusId === 7"
            text-variant="center"
            class="card card-congratulations"
          >
            <!-- images -->
            <b-img
              src="@/assets/images/elements/decore-left.png"
              class="congratulations-img-left"
            />
            <b-img
              src="@/assets/images/elements/decore-right.png"
              class="congratulations-img-right"
            />
            <!--/ images -->

            <b-avatar
              variant="success"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="CheckIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Pass
            </h1>
            <b-card-text class="m-auto w-75">
              For <strong>{{ finalDivisionName }}</strong> division
            </b-card-text>
          </b-card>

          <!-- If interview failed -->
          <b-card
            v-else-if="registrant.servolutionRegistrationStatusId === 8"
            text-variant="center"
            bg-variant="secondary"
          >
            <b-avatar
              variant="danger"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="XIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Interview fail for <strong>{{ registrant.firstMinistry.name }}</strong> division
            </h1>
          </b-card>
        </b-col>
      </b-row>
    </span>

    <!-- If the judge is for the second option -->
    <span v-else-if="registrant.secondMinistry && authorizedDivisions.indexOf(registrant.secondMinistry.id) > -1">
      <!-- If the user fail on first ministry and have second ministry -->
      <b-row>
        <!-- Show progress card by first option if the user is prepared to be auditioned -->
        <b-col
          v-if="registrant.servolutionRegistrationStatusId === 2"
          cols="12"
          lg="12"
        >
          <b-card
            text-variant="center"
            bg-variant="warning"
          >
            <b-avatar
              variant="warning"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="AlertCircleIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              This participant is still being assessed by another ministry.
            </h1>
          </b-card>
        </b-col>

        <!-- Show progress card by first option if the user is passed the audition and prepare to be interviewed -->
        <b-col
          v-if="registrant.servolutionRegistrationStatusId === 4 || registrant.servolutionRegistrationStatusId === 67 || registrant.servolutionRegistrationStatusId === 100 || (registrant.isPassedFirstOptionMinistry && registrant.servolutionRegistrationStatusId === 34)"
          cols="12"
          lg="12"
        >
          <!-- Show congratulations card -->
          <b-card
            v-if="registrant.servolutionRegistrationStatusId === 4"
            text-variant="center"
            class="card card-congratulations"
          >
            <!-- images -->
            <b-img
              src="@/assets/images/elements/decore-left.png"
              class="congratulations-img-left"
            />
            <b-img
              src="@/assets/images/elements/decore-right.png"
              class="congratulations-img-right"
            />
            <!--/ images -->

            <b-avatar
              variant="success"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="CheckIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Pass the Audition
            </h1>
          </b-card>

          <!-- Show congratulations card on PAW Kidz -->
          <b-card
            v-if="registrant.servolutionRegistrationStatusId === 67"
            text-variant="center"
            class="card card-congratulations"
          >
            <!-- images -->
            <b-img
              src="@/assets/images/elements/decore-left.png"
              class="congratulations-img-left"
            />
            <b-img
              src="@/assets/images/elements/decore-right.png"
              class="congratulations-img-right"
            />
            <!--/ images -->

            <b-avatar
              variant="success"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="CheckIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Pass the Audition for PAW Kidz
            </h1>
          </b-card>

          <!-- Show interview progress report -->
          <b-card
            text-variant="center"
            bg-variant="warning"
          >
            <b-avatar
              variant="warning"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="AlertCircleIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              This participant is still being assessed by another ministry.
            </h1>
          </b-card>
        </b-col>

        <!-- Show fail audition card -->
        <b-col
          v-if="!registrant.isPassedFirstOptionMinistry && (registrant.servolutionRegistrationStatusId === 6 || registrant.servolutionRegistrationStatusId === 7 || registrant.servolutionRegistrationStatusId === 8)"
          cols="12"
          lg="12"
        >
          <!-- show Card -->
          <b-card
            text-variant="center"
            bg-variant="secondary"
          >
            <b-avatar
              variant="danger"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="XIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Audition Failed
            </h1>
          </b-card>

          <!-- Show interview form -->
          <b-card
            v-if="!registrant.isPassedFirstOptionMinistry && registrant.servolutionRegistrationStatusId === 6"
            title="Interview Form"
          >
            <validation-observer ref="interviewSummaryForm">
              <b-form>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Dasar Kerohanian"
                      rules="required"
                    >
                      <b-form-group
                        label="1. Dasar Kerohanian"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="dasarKerohanian"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="dasarKerohanian"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="dasarKerohanianNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Apakah ada masalah hidup yang mendalam"
                      rules="required"
                    >
                      <b-form-group
                        label="2. Apakah ada masalah hidup yang mendalam"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="tidakAdaMasalahHidup"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="tidakAdaMasalahHidup"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="tidakAdaMasalahHidupNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Komitmen dalam Pelayanan"
                      rules="required"
                    >
                      <b-form-group
                        label="3. Komitmen dalam Pelayanan"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="komitmenDalamPelayanan"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="komitmenDalamPelayanan"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="komitmenDalamPelayananNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggung Jawab dalam Kehidupan"
                      rules="required"
                    >
                      <b-form-group
                        label="4. Tanggung Jawab dalam Kehidupan"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="tanggungJawabDalamKehidupan"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="tanggungJawabDalamKehidupan"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="tanggungJawabDalamKehidupanNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Kedewasaan dalam Cara Berpikir"
                      rules="required"
                    >
                      <b-form-group
                        label="5. Kedewasaan dalam Cara Berpikir"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="kedewasaanBerpikir"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="kedewasaanBerpikir"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <b-form-textarea
                          v-model="kedewasaanBerpikirNotes"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="role === 'PIC Ministry'"
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Interview Result"
                      rules="required"
                    >
                      <b-form-group
                        label="Lulus Interview ?"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="interviewResult"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="interviewResult"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      class="mr-1"
                      type="submit"
                      @click.prevent="validationInterviewForm"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      :to="{ name: 'servolution-participants', params: { id: eventId } }"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>

        <!-- Check if the interview has been conducted -->
        <b-col v-if="registrant.servolutionRegistrationStatusId === 7 || registrant.servolutionRegistrationStatusId === 8">
          <!-- Show interview summary form -->
          <b-card title="Interview Summary Report">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`1. Dasar Kerohanian`"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.dasarKerohanian"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`Catatan untuk Dasar Kerohanian`"
                    class="font-weight-bold"
                  >
                    <b-form-textarea
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.dasarKerohanianNotes === '' ? '-' : interviewSummaryReport.dasarKerohanianNotes"
                      rows="1"
                      max-rows="500"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`2. Apakah ada masalah hidup yang mendalam`"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.tidakAdaMasalahHidup"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`Catatan untuk Apakah ada masalah hidup yang mendalam`"
                    class="font-weight-bold"
                  >
                    <b-form-textarea
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.tidakAdaMasalahHidupNotes === '' ? '-' : interviewSummaryReport.tidakAdaMasalahHidupNotes"
                      rows="1"
                      max-rows="500"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`3. Komitmen dalam Pelayanan`"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.komitmenDalamPelayanan"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`Catatan untuk Komitmen dalam Pelayanan`"
                    class="font-weight-bold"
                  >
                    <b-form-textarea
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.komitmenDalamPelayananNotes === '' ? '-' : interviewSummaryReport.komitmenDalamPelayananNotes"
                      rows="1"
                      max-rows="500"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`4. Tanggung Jawab dalam Kehidupan`"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.tanggungJawabDalamKehidupan"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`Catatan untuk Tanggung Jawab dalam Kehidupan`"
                    class="font-weight-bold"
                  >
                    <b-form-textarea
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.tanggungJawabDalamKehidupanNotes === '' ? '-' : interviewSummaryReport.tanggungJawabDalamKehidupanNotes"
                      rows="1"
                      max-rows="500"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`5. Kedewasaan dalam Cara Berpikir`"
                    class="font-weight-bold"
                  >
                    komitmenDalamPelayananNotes
                    <b-form-input
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.kedewasaanBerpikir"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`Catatan untuk Kedewasaan dalam Cara Berpikir`"
                    class="font-weight-bold"
                  >
                    <b-form-textarea
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.kedewasaanBerpikirNotes === '' ? '-' : interviewSummaryReport.kedewasaanBerpikirNotes"
                      rows="1"
                      max-rows="500"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`Nama Interviewer`"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.namaInterviewer"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`Acc. Nama Kadept`"
                    class="font-weight-bold"
                  >
                    <b-form-input
                      plaintext
                      class="font-italic"
                      :value="interviewSummaryReport.accNamaKadept"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card>

          <!-- Show successful interview card -->
          <b-card
            v-if="registrant.servolutionRegistrationStatusId === 7"
            text-variant="center"
            class="card card-congratulations"
          >
            <!-- images -->
            <b-img
              src="@/assets/images/elements/decore-left.png"
              class="congratulations-img-left"
            />
            <b-img
              src="@/assets/images/elements/decore-right.png"
              class="congratulations-img-right"
            />
            <!--/ images -->

            <b-avatar
              variant="success"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="CheckIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Pass
            </h1>
            <b-card-text class="m-auto w-75">
              For <strong>{{ finalDivisionName }}</strong> division
            </b-card-text>
          </b-card>

          <!-- Show failed interview card -->
          <b-card
            v-if="registrant.servolutionRegistrationStatusId === 8"
            text-variant="center"
            bg-variant="secondary"
          >
            <b-avatar
              variant="danger"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="XIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Interview fail for <strong>{{ registrant.isPassedFirstOptionMinistry ? registrant.firstMinistry.name : registrant.secondMinistry.name }}</strong> division
            </h1>
          </b-card>
        </b-col>

        <!-- Check if the interview status needs approval from the PIC Ministry -->
        <b-col v-if="!registrant.isPassedFirstOptionMinistry && registrant.servolutionRegistrationStatusId === 34 && role === 'PIC Ministry'">
          <!-- show Card -->
          <b-card
            text-variant="center"
            bg-variant="secondary"
          >
            <b-avatar
              variant="danger"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="XIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              Audition Failed
            </h1>
          </b-card>

          <!-- Show Interview Summary report -->
          <b-card title="Interview Summary Report">
            <validation-observer ref="interviewSummaryForm">
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`1. Dasar Kerohanian`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.dasarKerohanian"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Dasar Kerohanian`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.dasarKerohanianNotes === '' ? '-' : interviewSummaryReport.dasarKerohanianNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`2. Apakah ada masalah hidup yang mendalam`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.tidakAdaMasalahHidup"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Apakah ada masalah hidup yang mendalam`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.tidakAdaMasalahHidupNotes === '' ? '-' : interviewSummaryReport.tidakAdaMasalahHidupNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`3. Komitmen dalam Pelayanan`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.komitmenDalamPelayanan"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Komitmen dalam Pelayanan`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.komitmenDalamPelayananNotes === '' ? '-' : interviewSummaryReport.komitmenDalamPelayananNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`4. Tanggung Jawab dalam Kehidupan`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.tanggungJawabDalamKehidupan"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Tanggung Jawab dalam Kehidupan`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.tanggungJawabDalamKehidupanNotes === '' ? '-' : interviewSummaryReport.tanggungJawabDalamKehidupanNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`5. Kedewasaan dalam Cara Berpikir`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.kedewasaanBerpikir"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Catatan untuk Kedewasaan dalam Cara Berpikir`"
                      class="font-weight-bold"
                    >
                      <b-form-textarea
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.kedewasaanBerpikirNotes === '' ? '-' : interviewSummaryReport.kedewasaanBerpikirNotes"
                        rows="1"
                        max-rows="500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Nama Interviewer`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.namaInterviewer"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="`Acc. Nama Kadept`"
                      class="font-weight-bold"
                    >
                      <b-form-input
                        plaintext
                        class="font-italic"
                        :value="interviewSummaryReport.accNamaKadept"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="registrant.servolutionRegistrationStatusId === 34 && role === 'PIC Ministry'">
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Interview Result"
                      rules="required"
                    >
                      <b-form-group
                        label="Lulus Interview ?"
                        :state="errors.length > 0 ? false:null"
                      >
                        <div
                          class="demo-inline-spacing"
                          style="margin-top: -15px;"
                        >
                          <b-form-radio
                            v-model="interviewResult"
                            value="ya"
                            class="custom-control-primary"
                          >
                            Ya
                          </b-form-radio>
                          <b-form-radio
                            v-model="interviewResult"
                            value="tidak"
                            class="custom-control-secondary"
                          >
                            Tidak
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="registrant.servolutionRegistrationStatusId === 34 && role === 'PIC Ministry'">
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      class="mr-1"
                      type="submit"
                      @click.prevent="validationInterviewForm"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      :to="{ name: 'servolution-participants', params: { id: eventId } }"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </span>
  </div>
</template>

<script>
import {
  BRow, BCol, BAvatar, BCard, BCardHeader, BCardBody, BBadge, BForm, BFormInput, BFormGroup, BFormRadio, BFormTextarea, BButton, BFormInvalidFeedback, BImg, BCardText,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Loading from 'vue-loading-overlay'
import * as moment from 'moment'
import _ from 'lodash'
import CryptoJS from 'crypto-js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// import { quillEditor } from 'vue-quill-editor'
import { isUserLoggedIn } from '@/auth/utils'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BForm,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    BImg,
    BCardText,
    // quillEditor,

    Loading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
      moment,
      eventId: '',
      registrant: null,
      firstDivisionType: null,
      interviewSummaryReport: {},
      cgPresencePercentage: 0,
      dasarKerohanian: '',
      dasarKerohanianNotes: '',
      tidakAdaMasalahHidup: '',
      tidakAdaMasalahHidupNotes: '',
      komitmenDalamPelayanan: '',
      komitmenDalamPelayananNotes: '',
      tanggungJawabDalamKehidupan: '',
      tanggungJawabDalamKehidupanNotes: '',
      kedewasaanBerpikir: '',
      kedewasaanBerpikirNotes: '',
      namaInterviewer: '',
      auditionResult: '',
      interviewResult: '',
      accNamaKadept: '',
      finalDivisionName: '',
      churchRegionalId: '',
      role: '',
      authorizedName: '',
      authorizedDivisions: [],
      snowOption: {
        theme: 'snow',
      },
      required,
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  async mounted() {
    // Set loading
    this.isLoading = true

    // Set data
    const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
    this.role = userData[0].role
    this.authorizedName = userData[0].name
    this.authorizedDivisions = userData[0].divisions

    // Get the user data
    const registrantDetails = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registration/${this.$route.params.id}`)
    this.registrant = registrantDetails.data
    this.eventId = this.registrant.servolutionEventsId

    // Get the church regional id based on church event
    const church = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/church/${this.registrant.servolutionEvents.churchId}`)
    this.churchRegionalId = church.data.parentCouchdbId

    // Get division type
    const division = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/church/ministry/${this.registrant.servolutionEvents.churchId}`)
    this.firstDivisionType = this.registrant.firstOptionMinistry ? _.find(division.data, { divisionsId: this.registrant.firstOptionMinistry }).ministryTypeId : null

    // Check status and role
    if (registrantDetails.data.servolutionRegistrationStatusId === 34 && this.role === 'PIC Servolution Assessment') {
      return this.$router.replace({ name: 'misc-not-authorized' })
    }

    // Get cg presence percentage
    const cgPresence = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registrant/cg/presence/${this.registrant.personId}`)
    this.cgPresencePercentage = cgPresence.data[0].result

    // Get summary report
    if (this.registrant.servolutionRegistrationStatusId === 7 || this.registrant.servolutionRegistrationStatusId === 8 || this.registrant.servolutionRegistrationStatusId === 34) {
      const interviewReport = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/interview/summary/${this.$route.params.id}`)
      this.interviewSummaryReport = interviewReport.data

      // Get final division if pass the interview
      if (this.registrant.servolutionRegistrationStatusId === 7) {
        const divisionResult = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/volunteer/new/${this.registrant.personId}`)
        this.finalDivisionName = divisionResult.data[0].division
      }
    }

    // Stop loading
    this.isLoading = false
  },
  methods: {
    cgPresenceResult(result) {
      let badge = 'light-danger'
      if (result >= 81.25) {
        badge = 'light-success'
      } else if (result >= 56.25) {
        badge = 'light-info'
      } else if (result >= 31.25) {
        badge = 'light-warning'
      }

      return badge
    },
    validationAuditionForm() {
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()

      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      this.$refs.auditionForm.validate().then(success => {
        if (success) {
          // Check if user has access
          if (!this.$can('update', 'servolution_assessment_audition')) {
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Set data
          const data = {
            updatedAt: new Date(),
          }

          // Check result
          let status = 4
          let isPassedFirstOptionMinistry = true
          // PAW Kidz
          if (this.auditionResult === 'paw_kidz') {
            // Set status
            status = 67
          } else if (this.auditionResult === 'tidak') {
            // Update status
            isPassedFirstOptionMinistry = false

            // Check for the servolution type
            // If the type is first and second
            if (this.registrant.servolutionEvents.servolutionTypeId === 1) {
              // Check if there is a second choice
              if (this.registrant.secondOptionMinistry) {
                // Set status to second chance
                status = 6
              } else {
                // Set status to "audition failed"
                status = 5
              }
            } else {
              // One option only
              // Set status to "audition failed
              status = 5
            }
          }
          data.isPassedFirstOptionMinistry = isPassedFirstOptionMinistry
          data.servolutionRegistrationStatusId = status

          // Update
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Update it!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            // Show Loading
            this.isLoading = true

            if (result.value) {
              this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/audition/${this.$route.params.id}`, data)
                .then(() => {
                  this.isLoading = false

                  // Redirect to role lists
                  this.$router.replace({ name: 'servolution-participants', params: { id: this.eventId } })
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: 'Data has been saved successfully.',
                        },
                      })
                    })
                })
            } else {
              // Hide loading
              this.isLoading = false
            }
          })
        }
      })
    },
    validationInterviewForm() {
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      this.$refs.interviewSummaryForm.validate().then(success => {
        if (success) {
          // Check if user has access
          if (!this.$can('update', 'servolution_assessment_interview')) {
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Update
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Update it!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(async result => {
            // Show Loading
            this.isLoading = true

            if (result.value) {
              // Set interview summary result
              const interviewSummaryData = {}

              // Nama interviewer
              if (this.role === 'PIC Servolution Assessment') {
                interviewSummaryData.namaInterviewer = this.authorizedName
              }

              // Acc Kadept
              if (_.isEmpty(this.interviewSummaryReport) && (this.role === 'PIC Ministry' || this.role === 'PIC Servolution Regional' || this.role === 'PIC Servolution Local' || this.role === 'Admin')) {
                interviewSummaryData.namaInterviewer = this.authorizedName
                interviewSummaryData.accNamaKadept = this.authorizedName
              } else if (this.role === 'PIC Ministry' || this.role === 'PIC Servolution Regional' || this.role === 'PIC Servolution Local' || this.role === 'Admin') {
                interviewSummaryData.accNamaKadept = this.authorizedName
              }

              // Save summary data
              if (_.isEmpty(this.interviewSummaryReport)) {
                interviewSummaryData.servolutionRegistrationsId = this.registrant.id
                interviewSummaryData.dasarKerohanian = this.dasarKerohanian
                interviewSummaryData.dasarKerohanianNotes = this.dasarKerohanianNotes
                interviewSummaryData.tidakAdaMasalahHidup = this.tidakAdaMasalahHidup
                interviewSummaryData.tidakAdaMasalahHidupNotes = this.tidakAdaMasalahHidupNotes
                interviewSummaryData.komitmenDalamPelayanan = this.komitmenDalamPelayanan
                interviewSummaryData.komitmenDalamPelayananNotes = this.komitmenDalamPelayananNotes
                interviewSummaryData.tanggungJawabDalamKehidupan = this.tanggungJawabDalamKehidupan
                interviewSummaryData.tanggungJawabDalamKehidupanNotes = this.tanggungJawabDalamKehidupanNotes
                interviewSummaryData.kedewasaanBerpikir = this.kedewasaanBerpikir
                interviewSummaryData.kedewasaanBerpikirNotes = this.kedewasaanBerpikirNotes
                await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/interview/summaries`, interviewSummaryData)
              } else {
                interviewSummaryData.updatedAt = new Date()
                await this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/interview/summaries/${this.interviewSummaryReport.id}`, interviewSummaryData)
              }

              // Set registrant data
              const registrantData = {
                updatedAt: new Date(),
              }

              // Set status based on role
              let status = 7
              if (this.role === 'PIC Ministry' || this.role === 'PIC Servolution Regional' || this.role === 'PIC Servolution Local' || this.role === 'Admin') {
                if (this.interviewResult === 'tidak') {
                  status = 8
                }
              } else if (this.role === 'PIC Servolution Assessment') {
                status = 34
              }
              registrantData.servolutionRegistrationStatusId = status

              // Check for the final division
              let finalDivision = null
              if (_.isNull(this.registrant.isPassedFirstOptionMinistry)) {
                finalDivision = this.registrant.firstOptionMinistry
              } else if (this.registrant.isPassedFirstOptionMinistry) {
                finalDivision = this.registrant.firstOptionMinistry
              } else if (!this.registrant.isPassedFirstOptionMinistry) {
                finalDivision = this.registrant.secondOptionMinistry
              }
              
              // console.log(isPassedFirstOpt)
              // if (this.registrant.isPassedFirstOptionMinistry)
              // if (this.registrant.servolutionRegistrationStatusId === 6 || this.registrant.servolutionRegistrationStatusId === 34) {
              //   finalDivision = this.registrant.secondOptionMinistry
              // }

              // Update registrant details
              this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/interview/${this.$route.params.id}/${finalDivision}`, registrantData)
                .then(() => {
                  this.isLoading = false

                  // Redirect to participants
                  this.$router.replace({ name: 'servolution-participants', params: { id: this.eventId } })
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: 'Data has been saved successfully.',
                        },
                      })
                    })
                })
            } else {
              // Hide loading
              this.isLoading = false
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>